import moment from 'moment'

const mountDefault = (incident) => {
  const incidentViolationTypes = incident?.incidentViolationTypes?.map(
    (item) => {
      return {
        id: item?.value,
      }
    },
  )

  if (incident?.legalOpinion) {
    delete incident.legalOpinion
  }

  if (incident?.nonComplianceReport?.entity?.legalOpinion) {
    delete incident.nonComplianceReport.entity.legalOpinion
  }
  return {
    ...incident,
    incidentViolationTypes,
    ...incident?.dataOptionIncidents,
    ...incident?.incidentDataAgents,
    ...incident?.communicationIncidentHolder,
    ...incident?.incidentReporting,
    incidentHour: moment(incident?.incidentDatetime).format('HH:mm:ss') || '',
    incidentDate: moment(incident?.incidentDatetime).format('DD/MM/YYYY') || '',
  }
}

export default mountDefault
