import moment from 'moment'

import onlyNumbers from '../formatters/onlyNumbers'

import constants from 'constants/index'

const mountCreate = (data, recaptcha) => {
  const {
    document,
    companyName,
    segmentId,
    responsibleName,
    responsibleEmail,
    phone,
    planId,
  } = data
  const documentNumber = onlyNumbers(document)
  const phoneNumber = onlyNumbers(phone)

  return {
    document: documentNumber,
    kind: documentNumber.length === 11 ? 'P' : 'C',
    name: companyName,
    fantasyName: companyName,
    email: responsibleEmail,
    phone: phoneNumber,
    planId,
    status: constants.preRegistrations.TRIAL_LIMITED_STATUS,
    segmentId,
    responsibleUser: {
      name: responsibleName,
      email: responsibleEmail,
      phone: phoneNumber,
    },
    demoPartner: {
      endDate: moment().add(1, 'month').format('YYYY-MM-DD'),
      kind: 'limited',
    },
    recaptcha: recaptcha,
  }
}

export default mountCreate
