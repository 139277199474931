import React from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import PropTypes from 'prop-types'
import { Box, Grid, Link, Paper, Typography } from '@material-ui/core'

import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import { routes } from 'Routes'
import useStyles from './styles'
import constants from 'constants/index'
import { ButtonAction } from 'components'

const MainBaseStructure = ({ subcategories, categoryId }) => {
  const classes = useStyles()
  const history = useHistory()
  const { company } = useAuth()

  const { LGPD_ID } = constants.technicalFaqs

  const handleBaseSelection = (subcategory) => {
    const eventName = `base_de_conhecimento-${
      categoryId === LGPD_ID ? 'lgpd' : 'si'
    }`
    const actionName =
      (subcategory?.title.replace(/\s+/g, '_') || '') +
      `${categoryId === LGPD_ID ? '-lgpd' : '-si'}`

    const eventData = {
      action: actionName,
    }

    helpers.mixpanel.track(eventName, actionName, eventData)

    if (categoryId === LGPD_ID) {
      history.push(
        reverse(routes.technicalFaqs.lgpdSpecificShow, {
          subCategoryId: subcategory?.id,
        }),
      )
    } else {
      history.push(
        reverse(routes.technicalFaqs.informationSecuritySpecificShow, {
          subCategoryId: subcategory?.id,
        }),
      )
    }
  }

  const redirectToTicketOpening = () => {
    if (!company?.defaultTicketChannel) {
      return window.open(company?.externalTicketLink)
    }

    if (categoryId === LGPD_ID) {
      return history.push(routes.technicalFaqs.lgpdTicket)
    }

    history.push(routes.technicalFaqs.informationSecurityTicket)
  }

  return (
    <Box className={classes.containerBox}>
      <Box className={classes.cardInfo}>
        <Typography gutterBottom variant="h6">
          EXPLORANDO NOSSA BASE DE CONHECIMENTO:
        </Typography>
        <Typography gutterBottom align="justify">
          Explore nossa base de conhecimento em um vasto repositório repleto de
          insights e informações valiosas. Cada card representa um tópico ou
          categoria, cuidadosamente organizado para sua conveniência. Seja uma
          dúvida técnica, histórica ou processual, temos os recursos adequados
          para esclarecê-la.
        </Typography>
        <Typography align="justify">
          Selecione um card de interesse e aprofunde-se no conteúdo. Nossa base
          é constantemente atualizada e refinada, garantindo informações
          precisas ao alcance de poucos cliques. Navegue, descubra e tire suas
          dúvidas conosco.
        </Typography>
      </Box>
      <Box>
        <Typography variant="h6">
          Selecione o tipo de base de conhecimento:
        </Typography>
      </Box>
      <Grid container spacing={3}>
        {subcategories?.map((subcategory) => (
          <Grid key={subcategory?.id} item xs={12} sm={6} md={4}>
            <Link
              onClick={() => {
                handleBaseSelection(subcategory)
              }}
              underline="none"
              className={classes.link}
            >
              <Paper
                className={classes.cardItem}
                variant="elevation"
                elevation={3}
              >
                <Typography variant="h6" gutterBottom>
                  {subcategory?.title}
                </Typography>
                <Typography align="justify">
                  {subcategory?.description}
                </Typography>
              </Paper>
            </Link>
          </Grid>
        ))}
      </Grid>
      <Box display="flex" justifyContent="flex-end" mt={3}>
        <ButtonAction
          variant="contained"
          color="primary"
          action={redirectToTicketOpening}
          name="Entrar em Contato"
        />
      </Box>
    </Box>
  )
}

MainBaseStructure.propTypes = {
  subcategories: PropTypes.arrayOf(PropTypes.object),
}

export default MainBaseStructure
