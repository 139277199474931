import React from 'react'
import PropTypes from 'prop-types'
import { Container, Box } from '@material-ui/core'
import { Check as CheckIcon, Moon as MoonIcon } from 'react-feather'

import UsersForm from './components/UsersForm'
import { Page, StatusCard } from 'components'
import palette from 'theme/palette'

import useFetch from 'hooks/useFetch'
import userCompaniesConstants from 'constants/userCompanies'

import * as service from 'service'
import constants from 'constants/index'

import useStyles from './styles'

const UsersEdit = ({ match }) => {
  const classes = useStyles()
  const {
    params: { userId },
  } = match

  const { response, isLoading, refresh } = useFetch(
    service.dponet.usersCompanies.list,
    {
      listByCompany: true,
      userId: userId,
      perPage: 9999999,
    },
  )

  const { response: responseProfiles, isLoading: loadingProfiles } = useFetch(
    service.dponet.profiles.get,
    {
      status: true,
      profileType: constants.profile.PROFILE_CLIENT_TYPE,
      perPage: 1000,
    },
  )

  const activeUser =
    response?.data?.userCompanies[0]?.status === userCompaniesConstants.ACTIVE

  return (
    <Page title="Editar usuário">
      <Container maxWidth={false} className={classes.container}>
        <Box mb={4}>
          <StatusCard
            backgroundIconColor={
              activeUser ? palette.greenPantone : palette.secondary.light
            }
            icon={
              activeUser ? (
                <CheckIcon size={18} color="white" />
              ) : (
                <MoonIcon size={18} color="white" />
              )
            }
            label="STATUS"
            value={activeUser ? 'Ativo' : 'Inativo'}
          />
        </Box>
        {!isLoading && (
          <UsersForm
            userCompanyId={response?.data?.userCompanies[0].id}
            user={response?.data?.userCompanies[0].user}
            userCompany={response?.data?.userCompanies[0]}
            onLoading={refresh}
            loadingProfiles={loadingProfiles}
            profiles={responseProfiles?.data?.profiles}
            isEdit
          />
        )}
      </Container>
    </Page>
  )
}
UsersEdit.propTypes = {
  match: PropTypes.object,
}

export default UsersEdit
