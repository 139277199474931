import constants from 'constants/index'

const isPending = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.PENDING_STATUS

const disapproved = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.DISAPPROVED_STATUS

const approved = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.APPROVED_STATUS

const inactive = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.INACTIVE

const isPendingLia = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.PENDING_LIA

const isReviewLia = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.REVIEW_LIA_CLIENT

const isApprovedLia = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.REVIEW_LIA_DPO

const isDisapprovedLia = (dataProcess) =>
  dataProcess?.statusId === constants.dataProcess.DISAPPROVED_LIA

const allowInactivation = (dataProcess) => {
  return (
    approved(dataProcess) ||
    (dataProcess?.version > 1 &&
      (isPending(dataProcess) ||
        disapproved(dataProcess) ||
        isDisapprovedLia(dataProcess) ||
        isPendingLia(dataProcess)))
  )
}

export default {
  approved,
  disapproved,
  inactive,
  isApprovedLia,
  isDisapprovedLia,
  allowInactivation,
  isPending,
  isPendingLia,
  isReviewLia,
}
