import userCompaniesConstants from 'constants/userCompanies'

const getChangeStatusText = (inactivateUser, user, userCompanyStatus) => {
  if (!user) return ''

  const isUserActive = userCompanyStatus === userCompaniesConstants.ACTIVE
  const hasDepartments = user?.vinculedDepartmentNames !== ''

  if (inactivateUser && hasDepartments && isUserActive) {
    return `Este usuário está vinculado ao(s) seguinte(s) departamento(s): "${user?.vinculedDepartmentNames}". Ao inativar o usuário, o(s) departamento(s) e os processos não serão excluídos. Certifique-se apenas de manter responsáveis pelo departamento.`
  }

  if (inactivateUser && isUserActive) {
    return `Você tem certeza que deseja inativar o usuário ${user?.name}?`
  }

  if (hasDepartments && isUserActive) {
    return `Este usuário está vinculado ao(s) seguinte(s) departamento(s): "${user?.vinculedDepartmentNames}". Ao excluir o usuário, o(s) departamento(s) e os processos não serão excluídos, mas o usuário será desvinculado deles. Certifique-se apenas de manter ao menos um responsável pelo(s) departamento(s).`
  }

  if (!inactivateUser) {
    return `Você tem certeza que deseja excluir o usuário ${user?.name}?`
  }

  return `Você tem certeza que deseja reativar o usuário ${user?.name}? Selecione um perfil para o usuário:`
}

export default getChangeStatusText
