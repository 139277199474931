import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Settings as SettingsIcon,
  LogOut as LogOutIcon,
  Layers as LayersIcon,
} from 'react-feather'
import {
  Box,
  ButtonBase,
  Menu,
  MenuItem,
  makeStyles,
  Typography,
  Divider,
  Link,
  Hidden,
} from '@material-ui/core'

import { LabelIconStart, UserAvatar, UserBox } from 'components'

import useAuth from 'hooks/useAuth'
import usePermission from 'hooks/usePermissions'
import useCustomTheme from 'hooks/useCustomTheme'

import { logowhite as logo } from 'helpers/reports/assets/logoWhite64'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import styles from './styles'

const useStyles = makeStyles(styles)

const Account = () => {
  const { isDpo } = usePermission()
  const { setCustomLogo } = useCustomTheme()

  const classes = useStyles()
  const ref = useRef(null)
  const auth = useAuth()
  const [isOpen, setOpen] = useState(false)
  const history = useHistory()

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const emptyCache = () => {
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name)
        })
      })

      window.location.reload(true)
    }
  }

  const handleLogout = () => {
    service.dponet.auth.logout()
    service.dponet.auth.clearStorage()
    setCustomLogo(logo)
    emptyCache()
    auth.loadOut()
  }

  const handleChangeCompany = () => {
    setCustomLogo(logo)
    auth.handleSelectCompany()
  }

  const handleSettings = () => {
    history.push(routes.settings)
  }

  const redirectToTerms = () => {
    window.open('https://www.dponet.com.br/termos-de-uso/')
  }

  const redirectToPrivacy = () => {
    window.open('https://www.dponet.com.br/politica-de-privacidade/')
  }

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        ref={ref}
        id={constants.myLgpd.HOME_DRIVER_STEP_2}
      >
        <UserAvatar />

        <Hidden smDown>
          <UserBox />
        </Hidden>
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        classes={{ list: classes.menuList }}
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
      >
        <Box ml={1} my={1} display="flex">
          <Typography variant="subtitle1">Perfil</Typography>
        </Box>
        <Divider />
        {!isDpo() && (
          <MenuItem onClick={handleSettings}>
            <Box my={1}>
              <LabelIconStart
                variant="inherit"
                icon={<SettingsIcon size="20" />}
                label="Configurações"
              />
            </Box>
          </MenuItem>
        )}
        <Box>
          <MenuItem onClick={handleChangeCompany}>
            <Box my={1}>
              <LabelIconStart
                variant="inherit"
                icon={<LayersIcon size="20" />}
                label="Trocar de empresa"
              />
            </Box>
          </MenuItem>
        </Box>
        <MenuItem onClick={handleLogout}>
          <Box my={1}>
            <LabelIconStart
              variant="inherit"
              icon={<LogOutIcon size="20" />}
              label="Sair"
            />
          </Box>
        </MenuItem>
        <Divider />
        <MenuItem>
          <Link className={classes.pointer} onClick={redirectToTerms}>
            Termos e condições de uso
          </Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.pointer} onClick={redirectToPrivacy}>
            Aviso de privacidade
          </Link>
        </MenuItem>
      </Menu>
    </>
  )
}

export default Account
