import React, { useState } from 'react'

import { reverse } from 'named-urls'
import { routes } from 'Routes'

import usePermission from 'hooks/usePermissions'
import useSnackbar from 'hooks/useSnackbar'
import useFetch from 'hooks/useFetch'
import { useHistory } from 'react-router-dom'

import {
  MenuButton,
  DialogLogs,
  ConfirmationRiskDialog,
  DialogHistoryNotifications,
} from 'components'
import { Button, Box } from '@material-ui/core'

import {
  DialogConfirm,
  DialogReopen,
  InactivateDataProcessDialog,
  ReactivateDataProcessDialog,
  DeleteDataProcessDialog,
  DialogAcceptSuggested,
  DialogRefuseSuggested,
} from '../../../'

import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'
import PropTypes from 'prop-types'

const DataProcessMenuItems = ({
  id,
  dataProcess,
  origin,
  dataProcessHook,
  logs = {},
  setLoading = () => {},
  onEvent = () => {},
  ...rest
}) => {
  const history = useHistory()
  const hasOrigin = dataProcess.originDataProcessId !== null

  const { permitted } = usePermission()
  const snackbar = useSnackbar()

  // Dialogs States
  const [dialogConfirm, setDialogConfirm] = useState(false)
  const [dialogFragility, setDialogFragility] = useState(false)
  const [dialogReopen, setDialogReopen] = useState(false)
  const [dialogInactivateDataProcess, setDialogInactivateDataProcess] =
    useState(false)
  const [dialogReactivateDataProcess, setDialogReactivateDataProcess] =
    useState(false)
  const [dialogDeleteDataProcess, setDialogDeleteDataProcess] = useState(false)
  const [dialogLogs, setDialogLogs] = useState(false)
  const [dialogNotifications, setDialogNotifications] = useState(false)
  const [dialogAcceptSuggested, setDialogAcceptedSuggested] = useState(false)
  const [adoptedFragilityId, setAdoptedFragilityId] = useState(null)
  const [refuseDialog, setRefuseDialog] = useState({
    open: false,
    message: 'Deseja recusar a sugestão do processamento de dados atual?',
    type: 'refuse',
  })
  const [defaultFragilityId, setDefaultFragilityId] = useState(null)

  const openRefuse = (props) => {
    setRefuseDialog({ ...refuseDialog, ...props })
  }

  const { response } = useFetch(service.dponet.departments.get, {
    perPage: 1000,
  })

  const handleEdit = () => {
    history.push(
      reverse(routes.dataProcess.edit, { dataProcessId: dataProcess?.id }),
    )
  }

  const pendingOrDisapproved =
    helpers.dataProcess.statusesCheck.isPending(dataProcess) ||
    helpers.dataProcess.statusesCheck.disapproved(dataProcess)

  const pendingOrDisapprovedLia =
    helpers.dataProcess.statusesCheck.isPendingLia(dataProcess) ||
    helpers.dataProcess.statusesCheck.isDisapprovedLia(dataProcess)

  // Envia o processo para revisão
  const sendToReview = async () => {
    setLoading(true)
    setDialogConfirm(false)
    setDialogFragility(false)
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcess.id,
        statusId: constants.dataProcess.WAITING_REVIEW_STATUS,
        adoptedFragilityId: adoptedFragilityId || defaultFragilityId,
      })
      snackbar.open({
        message: 'Processo enviado para a revisão!',
        variant: 'success',
      })
      setLoading(false)
      onEvent()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Ocorreu um erro, tente novamente',
        variant: 'error',
      })
      setLoading(false)
    }
  }

  const getBackToList = () => {
    dataProcessHook.setReload(true)
  }

  const allowEdition = () => {
    return (
      permitted('create_data_process') &&
      (pendingOrDisapproved || pendingOrDisapprovedLia)
    )
  }

  const allowSendToRevision = () => {
    return permitted('create_data_process') && pendingOrDisapproved
  }

  const allowReopen = () => {
    return (
      permitted('reopen_data_process') &&
      helpers.dataProcess.statusesCheck.approved(dataProcess)
    )
  }

  const allowShowProcessHistory = () => {
    return !!logs.length
  }

  const allowInactivation = () => {
    return (
      permitted('inactive_and_reactive_data_process') &&
      helpers.dataProcess.statusesCheck.allowInactivation(dataProcess)
    )
  }

  const allowReactivate = () => {
    return (
      permitted('inactive_and_reactive_data_process') &&
      helpers.dataProcess.statusesCheck.inactive(dataProcess)
    )
  }

  const allowExclusion = () => {
    return permitted('destroy_data_process')
  }

  const allowAcceptedSuggestion = () => {
    return (
      permitted('list_and_manage_suggested_data_processes') &&
      (origin === 'DataProcessDiscarded' || origin === 'DataProcessSuggestion')
    )
  }

  const allowRefuseSuggestion = () => {
    return (
      permitted('list_and_manage_suggested_data_processes') &&
      origin === 'DataProcessSuggestion'
    )
  }

  const handleRevision = () => {
    if (dataProcess?.acceptProcessFragility) return setDialogFragility(true)

    return setDialogConfirm(true)
  }

  const items = [
    {
      label: 'EDITAR',
      action: () => handleEdit(),
      visible: allowEdition(),
    },
    {
      label: 'ENVIAR PARA REVISÃO',
      action: handleRevision,
      visible: allowSendToRevision(),
    },
    {
      label: 'REABRIR',
      action: () => setDialogReopen(true),
      visible: allowReopen(),
    },
    {
      label: 'HISTÓRICO DE ALTERAÇÕES',
      action: () => setDialogLogs(true),
      visible: allowShowProcessHistory(),
    },
    {
      label: 'HISTÓRICO DE NOTIFICAÇÕES',
      action: () => setDialogNotifications(true),
      visible: allowShowProcessHistory(),
    },
    {
      label: 'INATIVAR',
      action: () => setDialogInactivateDataProcess(true),
      visible: allowInactivation(),
    },
    {
      label: 'REATIVAR',
      action: () => setDialogReactivateDataProcess(true),
      visible: allowReactivate(),
    },
    {
      label: 'EXCLUIR',
      action: () => setDialogDeleteDataProcess(true),
      visible: !hasOrigin && allowExclusion(),
    },
    {
      label: 'EXCLUIR',
      action: () =>
        openRefuse({
          open: true,
          message:
            'Deseja realmente excluir o processo? Todas as alterações feitas no processo serão perdidas',
          type: 'discard',
        }),
      visible: hasOrigin && allowExclusion(),
    },
  ]

  const SuggestedMenuItems = [
    {
      label: 'ACEITAR',
      action: () => setDialogAcceptedSuggested(true),
      visible: allowAcceptedSuggestion(),
    },
    {
      label: 'RECUSAR',
      action: () => openRefuse({ open: true }),
      visible: allowRefuseSuggestion(),
    },
  ]

  var menuItems = []

  if (origin === 'DataProcessSuggestion' || origin === 'DataProcessDiscarded') {
    menuItems = SuggestedMenuItems
  } else {
    menuItems = items
  }

  const visibleItems = menuItems.filter((item) => item.visible)

  return (
    <>
      {visibleItems?.length ? (
        <MenuButton id={id} {...rest}>
          {visibleItems.map((item) => (
            <Button key={item.label} fullWidth onClick={item.action}>
              {item.label}
            </Button>
          ))}
        </MenuButton>
      ) : (
        <Box py={2} />
      )}
      {dialogConfirm && (
        <DialogConfirm
          title={'Enviar processo para a revisão'}
          description={'Deseja enviar esse processo para revisão?'}
          confirmText={'Enviar para revisão'}
          open={dialogConfirm}
          setOpen={setDialogConfirm}
          handleConfirm={sendToReview}
        />
      )}
      {dialogFragility && (
        <ConfirmationRiskDialog
          setAdoptedFragilityId={setAdoptedFragilityId}
          dataProcessId={dataProcess?.id}
          handleConfirm={sendToReview}
          setOpen={setDialogFragility}
          open={dialogFragility}
          setDefaultFragilityId={setDefaultFragilityId}
        />
      )}
      <DialogReopen
        open={dialogReopen}
        setOpen={setDialogReopen}
        dataProcess={dataProcess}
        setLoading={setLoading}
        refresh={onEvent}
        redirectTo={reverse(routes.dataProcess.edit, {
          dataProcessId: dataProcess.id,
        })}
      />
      <DialogLogs open={dialogLogs} setOpen={setDialogLogs} logs={logs} />
      <DialogHistoryNotifications
        open={dialogNotifications}
        setOpen={setDialogNotifications}
        notifications={dataProcess?.notificationMailerLogs}
      />
      <InactivateDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogInactivateDataProcess}
        setOpen={setDialogInactivateDataProcess}
        action={onEvent}
      />
      <ReactivateDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogReactivateDataProcess}
        setOpen={setDialogReactivateDataProcess}
        action={onEvent}
      />
      <DeleteDataProcessDialog
        dataProcessId={dataProcess?.id}
        open={dialogDeleteDataProcess}
        setOpen={setDialogDeleteDataProcess}
        action={getBackToList}
        redirectTo={routes.dataProcess.all}
      />
      <DialogAcceptSuggested
        open={dialogAcceptSuggested}
        setOpen={setDialogAcceptedSuggested}
        dataProcess={dataProcess}
        departments={response?.data?.departments || []}
        setIsLoading={setLoading}
      />
      <DialogRefuseSuggested
        refuseDialog={refuseDialog}
        setRefuseDialog={setRefuseDialog}
        dataProcess={dataProcess}
        setIsLoading={setLoading}
        redirectTo={routes.dataProcess.all}
      />
    </>
  )
}

DataProcessMenuItems.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dataProcess: PropTypes.object,
  origin: PropTypes.string,
  dataProcessHook: PropTypes.object,
  logs: PropTypes.array,
  setLoading: PropTypes.func,
  onEvent: PropTypes.func,
}

export default DataProcessMenuItems
