import { colors } from '@material-ui/core'

const white = '#FFFFFF'

const styles = (theme) => ({
  cardPadding: {
    padding: theme.spacing(2),
  },
  departmentEllipsis: {
    whiteSpace: 'noWrap',
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '20vw',
  },
  pendingChip: {
    backgroundColor: '#007bff',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  waitingReviewChip: {
    backgroundColor: '#ffc107',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  disapprovedChip: {
    backgroundColor: '#dc3545',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  approvedChip: {
    backgroundColor: '#28a745',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  inactiveChip: {
    backgroundColor: colors.grey[500],
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  totalChip: {
    backgroundColor: '#343a40',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  pendingLiaChip: {
    backgroundColor: '#66b3ff',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  reviewLiaChip: {
    backgroundColor: '#ffe08a',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
  disapprovedLiaChip: {
    backgroundColor: '#f28b8b',
    color: white,
    fontWeight: 700,
    minWidth: 90,
  },
})

export default styles
