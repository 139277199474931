import React from 'react'
import { useHistory } from 'react-router-dom'

import usePermissions from 'hooks/usePermissions'

import {
  Divider,
  Box,
  Card,
  CircularProgress,
  Chip,
  Grid,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core'

import { NoData } from 'components'

import { Archive } from 'react-feather'

import constants from 'constants/index'
import styles from './styles'
import qs from 'qs'

const useStyles = makeStyles(styles)

const ResumeCard = ({ resumes = [], isLoading }) => {
  const classes = useStyles()
  const history = useHistory()
  const permissions = usePermissions()

  const ListItemLink = (props) => {
    return <ListItem button component="a" {...props} />
  }

  const handleClickStatus = (statusId, departmentId) => {
    history.push({
      pathname: '/data-processes',
      search: qs.stringify(
        { statusId: statusId, departmentId: departmentId },
        { arrayFormat: 'repeat' },
      ),
    })
  }

  const handleClickAll = (departmentId) => {
    history.push({
      pathname: '/data-processes',
      search: qs.stringify({ departmentId: departmentId }),
    })
  }

  return (
    <>
      {isLoading ? (
        <Box
          display="flex"
          width="100%"
          minHeight="700px"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={3}>
          {resumes &&
            resumes?.map((resume) => {
              return (
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={6}
                  xs={12}
                  key={resume?.departament?.id}
                  id={constants.dataProcess.DATA_PROCESS_RESUME_DRIVER_STEP_3}
                >
                  <Card className={classes.cardPadding}>
                    <Box display="flex" alignItems="center" mb={2}>
                      <Archive size={22} />
                      <Box ml={1}>
                        <Tooltip title={resume?.departament?.name}>
                          <Typography
                            variant="h5"
                            className={classes.departmentEllipsis}
                          >
                            {resume?.departament?.name}
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Box>
                    <List component="nav">
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.PENDING_STATUS,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Pendentes"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.pendingChip}
                          label={resume?.resume?.pending}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.WAITING_REVIEW_STATUS,
                            resume?.departament.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Aguardando revisão"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.waitingReviewChip}
                          label={resume?.resume?.waitingReview}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.DISAPPROVED_STATUS,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Reprovados"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.disapprovedChip}
                          label={resume?.resume?.disapproved}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.PENDING_LIA,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Pendente Lia"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.pendingLiaChip}
                          label={resume?.resume?.pendingLia}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.REVIEW_LIA_STATUSES,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Revisão Lia"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.reviewLiaChip}
                          label={resume?.resume?.reviewLia}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.DISAPPROVED_LIA,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Reprovado Lia"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.disapprovedLiaChip}
                          label={resume?.resume?.disapprovedLia}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.APPROVED_STATUS,
                            resume?.departament?.id,
                          )
                        }
                        disabled={!permissions.permitted('list_data_processes')}
                      >
                        <ListItemText
                          primary="Aprovados"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.approvedChip}
                          label={resume?.resume?.approved}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        disabled={!permissions.permitted('list_data_processes')}
                        onClick={() =>
                          handleClickStatus(
                            constants.dataProcess.INACTIVE,
                            resume?.departament?.id,
                          )
                        }
                      >
                        <ListItemText
                          primary="Inativos"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.inactiveChip}
                          label={resume?.resume?.inactive}
                        />
                      </ListItemLink>
                      <Divider />
                      <ListItemLink
                        disabled={!permissions.permitted('list_data_processes')}
                        onClick={() => handleClickAll(resume?.departament?.id)}
                      >
                        <ListItemText
                          primary="Total"
                          primaryTypographyProps={{
                            variant: 'body1',
                          }}
                        />
                        <Chip
                          className={classes.totalChip}
                          label={resume?.resume?.total}
                        />
                      </ListItemLink>
                    </List>
                  </Card>
                </Grid>
              )
            })}
          <Grid item sm={12}>
            {!resumes?.length && (
              <NoData message="Nenhum dado foi encontrado." />
            )}
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default ResumeCard
