import React from 'react'

import { Typography, Box } from '@material-ui/core'

import { BaseDialog } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import * as service from 'service'

import constants from 'constants/index'
import helpers from 'helpers'

const ReactivateDataProcessDialog = ({
  dataProcessId,
  open,
  setOpen = () => {},
  action = () => {},
}) => {
  const snackbar = useSnackbar()

  const handleInactivate = async () => {
    try {
      await service.dponet.dataProcesses.changeStatus({
        dataProcessId: dataProcessId,
        statusId: constants.dataProcess.PENDING_STATUS,
      })
      snackbar.open({
        message: 'Processamento de dados reativado com sucesso!',
        variant: 'success',
      })
      setOpen(false)
      action()
    } catch (error) {
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error) ||
          'Erro ao reativar processamento de dados.',
        variant: 'error',
      })
      setOpen(false)
    }
  }

  return (
    <BaseDialog
      open={open}
      title="Você tem certeza de que deseja reativar este processo?"
      closeButtonText="Cancelar"
      actionButtonText="Confirmar reativação"
      actionButton={handleInactivate}
      setOpen={setOpen}
    >
      <Box my={1}>
        <Typography>
          Ao reativar um processo inativo, você está indicando que a atividade
          de tratamento de dados pessoais voltou a existir na rotina da sua
          empresa.
        </Typography>
      </Box>
    </BaseDialog>
  )
}

export default ReactivateDataProcessDialog
